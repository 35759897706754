import React, {useState} from 'react';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { imagesFavourites } from '../assets/favouriteImages';

const FavouritesGallery = () => {
  
    const [index, setIndex] = useState(-1);

    const currentImage = imagesFavourites[index];
    const nextIndex = (index + 1) % imagesFavourites.length;
    const nextImage = imagesFavourites[nextIndex] || currentImage;
    const prevIndex = (index + imagesFavourites.length - 1) % imagesFavourites.length;
    const prevImage = imagesFavourites[prevIndex] || currentImage;
  
    const handleClick = (index) => {
      setIndex(index);
    }
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);  
 return (
        <div>   
        <Gallery 
          images={imagesFavourites} 
          onClick={handleClick} 
          enableImageSelection={false}
        />
        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.original}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
        </div> 
    );

};

export default FavouritesGallery;